
























































































































































































































































































































































































import { defineComponent } from "@vue/composition-api";
import moment from "moment";
import { mapStores } from "pinia";
import { mapActions } from "vuex";
import type { IomodelsCoachappAfgenomenMeting } from "@/lib/backend/club.api";
import { useGebruikerStore } from "@/pinia/gebruiker";
import { usePopupStore } from "@/pinia/popup";
import { getDayMonthYear } from "@/utils/date";

type Data = {
	loading: boolean;
	editing: boolean;
	metingRecord: Omit<IomodelsCoachappAfgenomenMeting, "lidId" | "metingDatum"> & {
		metingDatum: Date;
	};
};

export default defineComponent({
	name: "PfgGebruikerMeting",
	props: {},
	data(): Data {
		return {
			loading: false,
			editing: false,
			metingRecord: {
				metingId: undefined,
				metingDatum: new Date(),
				bovendruk: undefined as unknown as number,
				onderdruk: undefined as unknown as number,
				hartslag: undefined as unknown as number,
				vetpercentage: undefined as unknown as number,
				gewicht: undefined as unknown as number,
				lengte: undefined as unknown as number,
				conditie: undefined as unknown as number,
				bmi: undefined as unknown as number,
				muscleMass: undefined as unknown as number,
				waistCircumference: undefined as unknown as number,
			},
		};
	},
	computed: {
		...mapStores(useGebruikerStore, usePopupStore),
		fieldErrors() {
			return (fieldName: string) =>
				(
					this.errors as unknown as {
						errors: Array<{ field: string; rule: string }>;
					}
				).errors.filter((error) => error.field === fieldName);
		},
		fieldErrorRule() {
			return (fieldName: string) => {
				return (
					this.errors as unknown as {
						firstRule: (name: string) => string | null;
					}
				).firstRule(fieldName);
			};
		},
		metingen(): IomodelsCoachappAfgenomenMeting[] {
			return this.gebruikerStore.metingen?.metingen ?? [];
		},
		laatsteMeting(): IomodelsCoachappAfgenomenMeting {
			const lastMeting = this.metingen.at(0);

			if (!lastMeting)
				return {
					bovendruk: undefined,
					onderdruk: undefined,
					hartslag: undefined,
					vetpercentage: undefined,
					gewicht: undefined,
					lengte: undefined,
					conditie: undefined,
					datum: undefined,
					muscleMass: undefined,
					waistCircumference: undefined,
					bmi: undefined,
				} as unknown as IomodelsCoachappAfgenomenMeting;
			else return lastMeting;
		},
		bmi(): string {
			if (this.metingRecord?.lengte && this.metingRecord?.gewicht) {
				return (this.metingRecord.gewicht / (this.metingRecord.lengte / 100) ** 2).toFixed(1);
			}

			return "";
		},
		isRequired() {
			return false;
			// return this.metingen.length === 0;
		},
	},
	watch: {
		$route: "check",
	},
	async created() {
		await this.check();
	},
	destroyed() {
		this.reset();
	},
	methods: {
		...mapActions("modal", ["openModal"]),
		reset() {
			// @ts-expect-error Apply not correctly typed
			Object.assign(this.$data, this.$options.data.apply(this));
		},
		async check() {
			this.loading = true;

			const { id, metingId } = this.$route.params;

			const numberId = Number(id);

			if (isNaN(numberId)) return;

			const currentId = this.gebruikerStore.id;

			if (currentId !== numberId) this.gebruikerStore.set(numberId);

			await this.getRequiredInfo();

			if (metingId) {
				const metingFound = this.gebruikerStore.getMetingById(Number(metingId));

				if (metingFound) {
					this.metingRecord = {
						metingId: metingFound.metingId,
						bmi: metingFound.bmi ? metingFound.bmi : undefined,
						bovendruk: metingFound.bovendruk ? metingFound.bovendruk : undefined,
						conditie: metingFound.conditie ? metingFound.conditie : undefined,
						gewicht: metingFound.gewicht ? metingFound.gewicht : undefined,
						hartslag: metingFound.hartslag ? metingFound.hartslag : undefined,
						lengte: metingFound.lengte ? metingFound.lengte : undefined,
						onderdruk: metingFound.onderdruk ? metingFound.onderdruk : undefined,
						vetpercentage: metingFound.vetpercentage ? metingFound.vetpercentage : undefined,
						muscleMass: metingFound.muscleMass ? metingFound.muscleMass : undefined,
						waistCircumference: metingFound.waistCircumference
							? metingFound.waistCircumference
							: undefined,
						metingDatum: moment(metingFound.metingDatum, "DD-MM-YYYY").toDate(),
					};
					this.editing = true;
				}
			}

			this.loading = false;
		},
		async getRequiredInfo() {
			await this.gebruikerStore.getMetingen();
		},
		async saveThisMeting() {
			/** Convert decimal values (with . or ,) to number */
			this.metingRecord.vetpercentage = Number(
				String(this.metingRecord.vetpercentage ?? 0).replace(",", "."),
			);
			this.metingRecord.muscleMass = Number(
				String(this.metingRecord.muscleMass ?? 0).replace(",", "."),
			);
			this.metingRecord.waistCircumference = Number(
				String(this.metingRecord.waistCircumference ?? 0).replace(",", "."),
			);

			if (this.bmi) this.metingRecord.bmi = Number(this.bmi);

			const isValid = await this.$validator.validateAll();

			if (!isValid) return;

			if (
				!this.metingRecord.bovendruk &&
				!this.metingRecord.onderdruk &&
				!this.metingRecord.hartslag &&
				!this.metingRecord.vetpercentage &&
				!this.metingRecord.conditie &&
				!this.metingRecord.gewicht &&
				!this.metingRecord.lengte &&
				!this.metingRecord.bmi &&
				!this.metingRecord.muscleMass &&
				!this.metingRecord.waistCircumference
			) {
				return this.popupStore.open({
					title: "Oeps!",
					body: "Er moet minimaal 1 waarde worden ingevuld om een nieuwe meting op te kunnen slaan.",
					buttons: {
						confirm: "OK",
					},
				});
			}

			this.loading = true;

			if (this.editing) await this.editThisMeting();
			else await this.createThisMeting();

			this.loading = false;
		},
		async editThisMeting() {
			await this.gebruikerStore.postMeting({
				bmi: this.metingRecord.bmi,
				bovendruk: this.metingRecord.bovendruk,
				conditie: this.metingRecord.conditie,
				gewicht: this.metingRecord.gewicht,
				hartslag: this.metingRecord.hartslag,
				lengte: this.metingRecord.lengte,
				onderdruk: this.metingRecord.onderdruk,
				vetpercentage: this.metingRecord.vetpercentage,
				muscleMass: this.metingRecord.muscleMass,
				waistCircumference: this.metingRecord.waistCircumference,
				metingId: this.metingRecord.metingId,
				metingDatum: this.metingRecord.metingDatum.toISOString(),
				lidId: this.gebruikerStore.id,
			});

			this.popupStore.open({
				title: "Gelukt!",
				body: "De meting is succesvol aangepast.",
				buttons: {
					confirm: "Sluiten",
				},
				callback: () =>
					this.$router.push({
						name: "Gebruiker metingen",
						params: { id: String(this.gebruikerStore.id) },
					}),
			});
		},
		async createThisMeting() {
			const { metingDatum, ...record } = this.metingRecord;

			// during first measurement everything has to be filled except conditie
			const hasEmptyFields = Object.entries(record).some(
				([name, value]) =>
					value === undefined &&
					!(
						["metingId", "conditie", "vetpercentage", "waistCircumference"] as Array<
							keyof typeof record
						>
					).includes(name),
			);
			const allEmptyFields = Object.entries(record).every(([, value]) => value === undefined);

			if (!this.metingen?.length && hasEmptyFields) {
				return this.popupStore.open({
					title: "Oeps!",
					body: "Aangezien dit de eerste meting is van dit lid, wordt er verwacht dat alle velden worden ingevuld.",
					buttons: {
						confirm: "OK",
					},
				});
			} else if (allEmptyFields) {
				return this.popupStore.open({
					title: "Oeps!",
					body: "Er moet tenminste 1 veld worden veranderd om de meting op te kunnen slaan.",
					buttons: {
						confirm: "OK",
					},
				});
			}

			await this.gebruikerStore.postMeting({
				bmi: this.metingRecord.bmi,
				bovendruk: this.metingRecord.bovendruk,
				conditie: this.metingRecord.conditie,
				gewicht: this.metingRecord.gewicht,
				hartslag: this.metingRecord.hartslag,
				lengte: this.metingRecord.lengte,
				onderdruk: this.metingRecord.onderdruk,
				vetpercentage: this.metingRecord.vetpercentage,
				muscleMass: this.metingRecord.muscleMass,
				waistCircumference: this.metingRecord.waistCircumference,
				lidId: this.gebruikerStore.id,
				metingDatum: this.metingRecord.metingDatum.toISOString(),
			});

			this.popupStore.open({
				title: "Gelukt!",
				body: "De meting is succesvol opgeslagen.",
				buttons: {
					confirm: "Sluiten",
				},
				callback: () =>
					this.$router.push({
						name: "Gebruiker metingen",
						params: { id: String(this.gebruikerStore.id) },
					}),
			});
		},
		getDayMonthYear,
	},
});
